<script>
import { mapActions } from 'vuex';

import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import Pagination from 'vue-pagination-2';
import ModalForm from '@/components/departments/ModalUserDepartamentComponent.vue';
import ModalTablePays from '@/components/departments/ModalTablePaysComponent.vue'
import ModalContractEnds from '@/components/contracts/ModalContractEndsComponent.vue'
import ModalFormCurrentPayments from '@/components/contracts/ModalFormCurrentPayments.vue';

export default {
    name: "contracts-table",
    components: {
        Pagination,
        ModalForm,
        ModalTablePays,
        ModalContractEnds,
        ModalFormCurrentPayments
    },
    data() {
        return {
            editForm: false,
            load: false,
            catalogue: new Catalogue(),
            page: 1,
            search: {
                search: '',
                building_id: '',
                company_id: '',
                status: 1,
                finish: '',
                user_id: '',
                month: '',
                payment_period_id: [1, 2, 3, 4, 6, 7, 8, 9]
            },
        }
    },
    methods: {
        downloadExcel() {
			let filter = this.search;
            let data = new URLSearchParams(filter).toString();

			let _url = this.globalUrlFiles + '/tenant?'+data;            
            window.open(_url, '_blank');            

			// Event.$emit('event-show-downloadFiles', filter);
		},
        getDiffDays(buy) {
            console.log('buy');
            console.log(buy);
            let className = '';
            const startDate = this.moment();
            const limitDate = this.moment().add(60, 'days');

            


            // Por terminar
            const fechaEspecifica = this.moment(buy.end_date);
            const fechaActual = this.moment();
            let dif_days = fechaEspecifica.diff(fechaActual, 'days');
            if (dif_days <= 30) {
                className = 'fa-solid fa-lightbulb mt-3 text-danger fa-xl fa-fade';
            }

            // Falta porcentaje
            let porcentaje = buy.rent_increase.some(increase => {
                const increaseDate = new Date(increase.date);
                return increaseDate >= startDate && increaseDate <= limitDate && increase.check;
            });

            if (porcentaje) {
                className = 'fa-solid fa-lightbulb mt-3 text-warning fa-xl fa-fade';
            }

            // Por incrementar
            let incr = buy.rent_increase.some(increase => {
                const increaseDate = new Date(increase.date);
                return increaseDate >= startDate && increaseDate <= limitDate && !increase.check;
            });

            if (incr) {
                className = 'fa-solid fa-lightbulb mt-3 text-info fa-xl fa-fade';
            }

            // Vencido sin finalizar           
            if ( new Date(buy.end_date) < startDate && buy.end_all == null) {                    
                className = 'fa-solid fa-lightbulb mt-3 text-purple fa-xl fa-fade';
            }

            return className;
        },
        downloadRecibe(id) {
            window.open(this.globalUrlFiles + '/contracts/pdf/' + id, '_blank');
        },
        async list(page = 1) {
            this.load = true;
            let service = request;
            let search = this.search;
            search.search = this.name;
            let _endpoint = '/tenant';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.buys = data;
                    this.load = false;
                }
            } catch (error) {
                console.log(error);
                this.load = false;
            }
        },
        deleted: function (id) {
            let tit = 'Eliminar contrato';
            let txt = 'Seguro que quiere eliminar el contrato';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/tenant/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        changeStatus: function (buy) {
            Event.$emit('event-show-changeStatusContracts', buy);
        },
        currentPayments: function (buy) {
            Event.$emit('event-show-currentPayments', buy);

            // let tit = buy.status == 1 ? 'Termina contrato' : 'Activar contrato';
            // let txt = buy.status == 1 ? 'Seguro que quiere termina el contrato' : 'Seguro que quiere activar el contrato';

            // this.$swal({
            //     title: tit,
            //     text: txt,
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Ok',
            //     cancelButtonText: 'Cancelar'
            // }).then(async (result) => {
            //     if (result.isConfirmed) {
            //         let _endpoint = '/tenant_status/' + buy.id;
            //         try {
            //             let response = await request.post(_endpoint).then(function (response) { return response; });
            //             this.list();
            //             this.successNotification(response.data.message);
            //         } catch (error) {
            //             this.errors = await this.errorNotification(error)
            //         }
            //     }
            // });
        },
        editBuy(buy = {}, edit = true) {
            this.editForm = edit;
            Event.$emit('event-show-addDepartamentUser', buy);
        },
        createFrom(buy) {
            this.editForm = true;
            Event.$emit('event-show-createFrom', buy);
        },
        showPays(pay) {
            Event.$emit('event-show-tablePays', pay);
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.list();
        this.catalogue.get_buildings();
        this.catalogue.get_companies_all();
        this.catalogue.get_users_profile(7);
    },
    created() {
        Event.$on("event-load-debts", () => {
            this.list();
        });
    },
    watch: {
        "search": {
            handler: function () {
                this.list();
            },
            deep: true
        },
    }
}

</script>
<template>
    <div class="col-12">
        <ModalForm :prop_edit="editForm" />
        <ModalTablePays />
        <ModalContractEnds />
        <ModalFormCurrentPayments />
        <div class="card card-outline card-info">
            <div class="card-header">
                <h3 class="card-title">Lista de Contratos</h3>
                <div class="card-tools">
                    <button @click="editBuy()" v-can="'add_contracts'" class="btn btn-sm mr-1 btn-outline-info">
                        <i class="fa fa-plus"></i> Contrato
                    </button>
                    <button @click="downloadExcel()" class="btn btn-sm btn-outline-success">
                        <i class="fa fa-file-excel"></i> Excel
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <!-- <div class="col-md-6">
                        <label>Buscar :</label>
                        <input type="text" class="form-control" v-model="search.name" @keyup="list()" />
                    </div> -->
                    <div class="col-md-6">
                        <label>Propiedad :</label>
                        <select class="form-control" v-model="search.building_id" @change="list()">
                            <option value="">Todas</option>
                            <option :value="building.id" v-for="(building, index) in catalogue.buildings" :key="index">
                                {{ building.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label>Empresa :</label>
                        <select class="form-control" v-model="search.company_id" @change="list()">
                            <option value="">Todas</option>
                            <option :value="company.id" v-for="(company, index) in catalogue.companies_all"
                                :key="index">{{ company.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label>Estatus :</label>
                        <select class="form-control" v-model="search.finish" @change="list()">
                            <option value="">Todas</option>
                            <option value="1">Por terminar</option>
                            <option value="2">Por incrementar</option>
                            <option value="3">Falta porcentaje </option>
                            <option value="4">Vencido sin finalizar</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label>Cliente :</label>
                        <select class="form-control" v-model="search.user_id" @change="list()">
                            <option value="">Todas</option>
                            <option :value="user.id" v-for="(user, user_index) in catalogue.users_profile"
                                :key="user_index">{{ user.name }} {{ user.last_name }}</option>
                        </select>
                    </div>
                    <div class="col-md-6" v-if="search.finish">
                        <label>Fecha :</label>
                        <select class="form-control" v-model="search.month" @change="list()">
                            <option value="">Todas</option>
                            <option value="1">Enero</option>
                            <option value="2">Febrero</option>
                            <option value="3">Marzo</option>
                            <option value="4">Abril</option>
                            <option value="5">Mayo</option>
                            <option value="6">Junio</option>
                            <option value="7">Julio</option>
                            <option value="8">Agosto</option>
                            <option value="9">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">

                    <div class="col-md-12">
                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="search.status = 1" data-toggle="pill" role="tab"
                                    aria-selected="true">Activos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="search.status = 2" data-toggle="pill" role="tab"
                                    aria-selected="false">Terminados</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th style="width: 50%;">Datos del pago</th>
                                <th>Fecha</th>
                                <th style="width: 140px;">Monto</th>
                                <th style="width: 140px;">Deposito de garantía</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody v-if="load">
                            <tr>
                                <td align="center" colspan="7">
                                    <i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
                                    <h5>Cargando</h5>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="buys.total > 0">
                            <tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in buys.data"
                                :key="index">
                                <td>
                                    {{ buy.id }}
                                    <!-- <i v-if="getDiffDays(buy) <= 30" class="fa-solid fa-lightbulb mt-3 text-danger fa-xl fa-fade"></i> -->
                                    <i :class="getDiffDays(buy)"></i>
                                    <!-- <div class=" mt-3" v-if="!buy.file">
											<i
												class="fa-solid fa-file-circle-exclamation text-warning fa-xl  fa-fade"></i>
										</div> -->

                                </td>
                                <td class="text-left">
                                    
                                    <h5 v-if="buy.is_current">
                                        <span class="badge badge-success">Al corriente</span>
                                    </h5>

                                    <strong>Propiedad:</strong><br>
                                    <span v-if="buy.building">
                                        {{ buy.building.name }}
                                    </span>
                                    <span v-else>Sin propiedad</span>
                                    <br>

                                    <strong>Departamento:</strong><br>
                                    <span v-if="buy.department">
                                        {{ buy.department.level }} / {{ buy.department.number }}
                                    </span>
                                    <span v-else>Sin departamento</span>
                                    <br>

                                    <strong>Usuario:</strong><br>
                                    <span v-if="buy.user">
                                        {{ buy.user.name }} {{ buy.user.last_name }}
                                    </span>
                                    <span v-else>Sin usuario</span>
                                    <br>

                                    <!-- Recurrencia -->
                                    <strong>Recurrencia:</strong><br>
                                    <span v-if="buy.payment_period"> {{ buy.payment_period.name
                                        }}</span>
                                    <span v-else>Sin Recurrencia</span>
                                    <hr>
                                    <strong>Servicios:</strong><br>
                                    <span v-if="buy.services.length > 0">
                                        <span class="badge badge-primary p-1 m-1"
                                            v-for="(serv, index_serv) in buy.services" :key="index_serv">
                                            {{ serv.name }}
                                        </span>
                                    </span>
                                    <span v-else>S/N</span>
                                    <hr>

                                    <button @click="showPays(buy)" type="button"
                                        class="btn btn-sm  m-1 btn-outline-info ">
                                        <i class="fa-solid fa-hand-holding-dollar"></i> Ver pagos
                                    </button>

                                    <button type="button" v-if="buy.deposit > 0" @click="downloadRecibe(buy.id)"
                                        class="btn m-1 btn-outline-secondary btn btn-sm ">
                                        <i class="fa-solid fa-file-pdf"></i> Recibo depósito de garantía
                                    </button>

                                    <button type="button" @click="changeStatus(buy)" v-can="'deleted_contracts'"
                                        :class="buy.status == 1 ? 'btn-outline-danger' : 'btn-outline-success'"
                                        class=" btn m-1 btn-sm ">
                                        <span v-if="buy.status == 1">
                                            <i class="fa-solid fa-power-off"></i> Terminar
                                        </span>
                                        <span v-else>
                                            <i class="fa-solid fa-power-off"></i> Activar
                                        </span>
                                    </button>

                                    <button @click="createFrom(buy)" type="button"
                                        class="btn btn-sm  m-1 btn-outline-secondary ">
                                        <i class="fa-solid fa-file-circle-plus"></i> Generar contrato
                                    </button>

                                    <button type="button" @click="deleted(buy.id)" v-can="'deleted_contracts'"
                                        class="btn-outline-danger btn m-1 btn-sm ">
                                        <i class="fa fa-trash"></i> Eliminar
                                    </button>

                                </td>
                                <td>
                                    <span v-if="buy.star_date"> {{ buy.star_date | toDateTime }} </span>
                                    <span v-if="buy.end_date"> - {{ buy.end_date | toDateTime }} </span>

                                </td>                               
                                <td class="text-right">
                                    {{ buy.amount | toCurrency }}
                                </td>
                                <td class="text-right">
                                    {{ buy.deposit | toCurrency }}
                                </td>

                                <!-- <td class="text-right">
										{{ paysCheks(buy.checks) | toCurrency }}
									</td>
									<td class="text-right">
										{{ buy.amount - paysCheks(buy.checks) | toCurrency }}
									</td> -->

                                <td>

                                    <button @click="editBuy(buy, false)" type="button"
                                        class="btn btn-sm  m-1 btn-outline-secondary ">
                                        <i class="fa-solid fa-eye"></i> Ver
                                    </button>
                                   
                                    <button @click="editBuy(buy)" v-can="'edit_contracts'"
                                        class="btn btn-outline-primary m-1 btn-sm">
                                        <i class="fas fa-edit "> </i> Editar
                                    </button>

                                    <button @click="currentPayments(buy)" v-can="'edit_contracts'" v-if="!buy.payment_date"
                                        class="btn btn-outline-success m-1 btn-sm">
                                        <i class="fa-solid fa-money-bills"></i>
                                        Poner al corriente
                                    </button>
                                                                                                                                         
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">Sin resultados.</td>
                            </tr>
                        </tbody>

                    </table>
                    <pagination v-model="page" :records="buys.total" :per-page="buys.per_page" @paginate="list" />
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #214d83;
    border-color: #dee2e6 #dee2e6 #fff;
}

.selected {
    color: #000;
    font-weight: bold;
    animation: blinkingText 4s infinite;
}

.text-purple{
    color: #322348;
}
</style>