<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                date: '',
                tenant_id: '',
                amount: 0,
            },           
            send: false,
            load: false,          
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.date = '';
            this.form.tenant_id = '';
            this.form.amount = 0;            
            this.send = false;
            this.load = false;
            this.errors = {};
            this.$modal.hide('currentPayment');

        },        
        async savePayment() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('date', this.form.date)
            data_form.append('amount', this.form.amount)       
            data_form.append('tenant_id', this.form.tenant_id)       

            let _endpoint = `/tenant_current_payment`;

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-debts');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    console.log(error.response);
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-currentPayments", async (contract) => {
            console.log(contract);      
            this.form.tenant_id = contract.id;
            if(contract.payment_date){
                this.form.date = contract.payment_date;
            }
            this.$modal.show('currentPayment');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="currentPayment" :width="'350px'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">      
        <form @submit.prevent="savePayment" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Poner al corriente el contrato</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                   
                    <div class="col-12">
                        <label for="">Fecha de fin</label>
                        <date-picker v-model="form.date" name="date" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>

                    <!-- <div class="col-12 mt-2">
                        <label for="">Monto adeudo</label>
                        <money class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div> -->
                
                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i> Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>