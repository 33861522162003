<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

import Multiselect from 'vue-multiselect'

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker,
        Multiselect,
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                date: '',
                company_id: '',
                date_start: '',
                date_end: '',
                dispersion: false,
                amount: '',
                employees: [],
                files: [],
                
            },           
            files: [],
            send: false,          
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.company_id = '';
            this.form.date = '';
            this.form.date_start = '';
            this.form.date_end = '';
            this.form.dispersion = false;
            this.form.amount = '';
            this.form.employees = [];
            this.form.files = [];
            this.files = [];
            this.send = false;
            this.errors = {};            
            this.$modal.hide('addPayroll');
        },
        uploadFilesBill(event) {
            let vm = this;

            let file = event.target.files[0];
            // let allowedImageTypes = ["image/jpeg", "image/gif", "image/png"];
            // if (!allowedImageTypes.includes(file.type)) {
            //     this.$swal({
            //         icon: 'error',
            //         title: 'Error en el tipo de archivo',
            //         text: 'Solo se acepta archivo tipo imagen',
            //     });
            // } else {
            vm.form.files.push(file);
            console.log(vm.form.files);
            this.$forceUpdate();

            // }
        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        calcTotal() {
            this.form.amount = this.form.subtotal + this.form.iva + this.form.ieps - this.form.isr_ret - this.form.iva_ret - this.form.discount;
        },
        calcTotalUSD() {
            this.form.subtotal = this.form.exchange_rate * this.form.amount_usd;
        },
        async delImgUpload(pos) {
            let img = this.files[pos];
            let id = img.id;
            let _endpoint = '/payroll/deleted-documents';
            let form = {
                id: id
            };
            let { data } = await request.postMedia(_endpoint, form)
            if (data) {
                this.successNotification(data.message);
                this.files.splice(pos, 1);
            }

        },
        delImg(pos) {
            this.form.files.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
                this.catchDataXML(event.target.files[0]);


            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },       
        async saveBuildings() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('date', this.form.date);
            data_form.append('company_id', this.form.company_id);
            data_form.append('date_start', this.form.date_start);
            data_form.append('date_end', this.form.date_end);
            data_form.append('dispersion', this.form.dispersion);
            data_form.append('amount', this.form.amount);
            data_form.append('employees', this.form.employees.map(a => a.id));

            this.form.files.forEach(function (valor, index) {
                data_form.append('files[' + index + ']', valor);
            });


            let _endpoint = '/payroll';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/payroll/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-payroll');
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }
        },
        // Check
        getBuysBudgets(user_id) {
            console.log('paso0');
            console.log(user_id);
            console.log(this.form.concept_id);

            if (user_id && this.form.check_type_id) {
                console.log('paso');
                let company_id = this.form.company_id;
                this.catalogue.get_budgets_user(user_id, this.form.check_concept_id, company_id);
                this.catalogue.get_buys_user(user_id, this.form.check_concept_id, company_id);
            }
        },
        getDataBudget() {
            let budgets = this.catalogue.budgets_user;
            console.log(budgets);

            let budget_id = this.form.check_budget_id;
            let res = budgets.find((s) => s.id === budget_id);
            console.log(res.concept_id);
            this.form.check_concept_id = res.concept_id;
        },
        getDataBuy() {
            let buys = this.catalogue.buys_user;
            console.log(buys);
            let buy_id = this.form.check_buy_id;
            console.log(buy_id);
            let res = buys.find((s) => s.id === buy_id);
            console.log(res);
            this.form.check_concept_id = res.concept_id;
        },
        //check

        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    watch: {       
        'form.company_id'(value) {           
            this.catalogue.get_company_user_payroll(value);
        },       
    },
    mounted() {       
        this.catalogue.get_company_pays();       
    },
    created() {
        Event.$on("event-show-addPayroll", async (account) => {
            console.log(account);
            if (account.id) {
                this.load = true;
                this.form.id = account.id;
                this.form.date = account.date;
                this.form.company_id = account.company_id;
                this.form.date_start = account.date_start;
                this.form.date_end = account.date_end;
                this.form.dispersion = account.dispersion;
                this.form.amount = account.amount;
                
                await this.catalogue.get_company_user_payroll(account.company_id);
                
                const employees = this.catalogue.company_user_payroll.filter(user => account.employees.includes(user.id));

                this.form.employees = employees;




                if (account.files) {
                    this.files = account.files;
                }
            }
            this.$modal.show('addPayroll');
        });
        Event.$on("event-show-addPayrollPettyCash", async(petty_cash) => {
            console.log('petty_cash');
            console.log(petty_cash);
            this.petty_cash = petty_cash.id;
            this.form.name = "caja chica #"+petty_cash.id;
            this.form.description = "caja chica #"+petty_cash.id;
            this.form.company_id = petty_cash.company_id;
            this.form.biill_type_id = petty_cash.type_id;
            this.form.subtotal = petty_cash.amount;
            this.$modal.show('addPayroll');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="addPayroll" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-6 mt-3">
                        <label>Fecha</label>
                        <date-picker v-model="form.date" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Empresa</label>
                        <select v-model="form.company_id" :disabled="!prop_edit" class="form-control">
                            <option value=""> Sin empresa </option>
                            <option v-for="(company, index) in catalogue.company_pays" :value="company.id" :key="index">
                                {{ company.name }}</option>
                        </select>	
                        <div v-if="errors && errors.company_id" class="text-danger">
                            {{ errors.company_id[0] }}
                        </div>
                    </div>

                    <div class="col-md-5 mt-3">
                        <label>Fecha inicio</label>
                        <date-picker v-model="form.date_start" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date_start" class="text-danger">{{ errors.date_start[0] }}</div>
                    </div>

                    <div class="col-md-5 mt-3">
                        <label>Fecha fin</label>
                        <date-picker v-model="form.date_end" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date_end" class="text-danger">{{ errors.date_end[0] }}</div>
                    </div>

                    <div class="col-md-2 mt-3 text-center">
                        <label>Dispersión</label>
                        <input :disabled="!prop_edit" type="checkbox" class="form-control form-control-sm"
                            v-model="form.dispersion">
                        <div v-if="errors && errors.dispersion" class="text-danger">{{ errors.dispersion[0] }}</div>
                    </div>                
                
                    <div class="col-md-3 mt-3">
                        <label v-if="form.dispersion">Total dispersión</label>
                        <label v-else>Monto a pagar</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>

                    <div class="col-md-9 mt-3" v-if="form.dispersion">
                        <label> Empleados </label>
                        <multiselect v-model="form.employees" :disabled="!prop_edit" tag-placeholder="Agregar giro"
                            placeholder="Buscar o agregar una giro" label="name" track-by="id"
                            :options="catalogue.company_user_payroll" :multiple="true" :taggable="true">
                        </multiselect>
                        <div v-if="errors && errors.employees" class="text-danger">{{ errors.employees[0] }}</div>
                    </div>
                 
                    <div class="col-12">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3>Archivo adjuntos.</h3>
                                <p>Máximo 15 archivos.</p>
                                <div class="row mt-4 mb-4">
                                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
                                        :key="index_fAdd" v-show="files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
                                        :key="index_img" v-show="form.files.length > 0">
                                        <div class="card shadow">
                                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="input-group mb-3"
                                    v-if="(form.files.length + files.length) <= 14 && prop_edit">
                                    <div class="custom-file" @change="uploadFilesBill">
                                        <input type="file" class="custom-file-input" id="inputGroupFile03"
                                            aria-describedby="inputGroupFileAddon03">
                                        <label class="custom-file-label" for="inputGroupFile03">
                                            Subir Archivo
                                        </label>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                  
                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" :disabled="send" type="submit"
                            class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                        </button>
                        <button type="button" :disabled="send" @click="closedModal()"
                            class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.image-upload>input {
    display: none;
}

.del {
    position: absolute;
    right: 0px;
    margin: 10px;
}
</style>