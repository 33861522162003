<script>
import { mapGetters } from 'vuex';
import Catalogue from '@/services/catalogue';
export default {
	data() {
		return {
			catalogue: new Catalogue(),
		}
	},
	methods: {

	},
	async mounted() {
		this.catalogue.get_count_bill_check();
		this.catalogue.get_checks_authorize();
		this.catalogue.get_paid_checks();
		this.catalogue.get_paid_checks_tax();
		if(this.authUser.organization_charts_id == 5){
			this.catalogue.get_buildings_resident(this.authUser.id);
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	created() {
		Event.$on("event-load-checks", () => {
			this.catalogue.get_count_bill_check();
			this.catalogue.get_paid_checks_tax();
		});
		Event.$on("event-load-pays", () => {
			this.catalogue.get_paid_checks();
		});
		Event.$on("event-load-checks-approved", () => {
			this.catalogue.get_checks_authorize();
			this.catalogue.get_paid_checks();
		});
		Event.$on("event-load-bills", () => {
			this.catalogue.get_count_bill_check();
			this.catalogue.get_paid_checks_tax();
		});
		Event.$on("event-load-bills-tax", () => {
			this.catalogue.get_paid_checks_tax();
		});
	}
}

</script>
<template>
	<nav class="mt-2">
		<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
			<!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
			<li class="nav-item">
				<router-link :to="{ name: 'app-home' }" class="nav-link">
					<i class="nav-icon fas fa-th"></i>
					<p>
						Panel principal
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'users'">
				<router-link to="/users" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-user"></i>
					<p v-if="authUser.organization_charts_id == 1 || authUser.organization_charts_id == 11">
						Usuarios
					</p>
					<p v-else>
						Proveedores
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'buildings'">
				<router-link to="/buildings" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-map-marker-alt"></i>
					<p>
						Propiedades
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-if="catalogue.buildings_resident.length > 0">
				<router-link to="/petty_cash" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-cash-register"></i>
					<p>
						Caja chica
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'petty_cash'" v-if="authUser.organization_charts_id != 5">
				<router-link to="/petty_cash" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-cash-register"></i>
					<p>
						Caja chica
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'contracts'">
				<router-link to="/contracts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-file-contract"></i>
					<p>
						Contratos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'debts'">
				<router-link to="/debts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-magnifying-glass-dollar"></i>
					<p>
						Adeudos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'debts_reports'">
				<router-link to="/debts_reports" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-table"></i>
					<p>
						Reporte de Adeudos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'debts_list_report'">
				<router-link to="/debts_list_report" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-list"></i>
					<p>
						Reporte de Lista
					</p>
				</router-link>
			</li>
			
			<li class="nav-item" v-can="'rules'">
				<router-link to="/rules" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-pen-ruler"></i>
					<p>
						Reglas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'concepts'">
				<router-link to="/concepts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-clipboard-list"></i>
					<p>
						Conceptos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'companies'">
				<router-link to="/companies" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-id-card-clip"></i>
					<p>
						Empresas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'bank_accounts'">
				<router-link to="/bank_accounts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-money-check-dollar"></i>
					<p>
						Cuentas de Bancos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'permissions'">
				<router-link to="/permissions" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-lock"></i>
					<p>
						Permisos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'tax'">
				<router-link to="/tax" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-cash-register"></i>
					<p>
						Impuestos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'pays_tax'">
				<router-link to="/pays_tax" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-money-check-alt"></i>
					<p>
						Pagar Impuestos
						<span v-can="'pays_tax'" class="float-right badge badge-danger">
							{{ this.catalogue.count_paid_checks_tax }}
						</span>							
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'bill'">
				<router-link to="/bill" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-file-invoice-dollar"></i>
					<p>
						Facturas
						<span v-can="'approve_bill'" class="float-right badge badge-danger">
							{{ this.catalogue.count_bill_check }}
						</span>
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'budgets'">
				<router-link to="/budgets" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-coins"></i>
					<p>
						Presupuestos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'buy'">
				<router-link to="/buy" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-basket-shopping"></i>
					<p>
						Gastos recurrentes
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'checks'">
				<router-link to="/checks" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-receipt"></i>
					<p>
						Cheques
						<span v-can="'approve_checks'" class="float-right badge badge-danger">
							{{ this.catalogue.count_checks_authorize }}
						</span>
						<span v-if="authUser.organization_charts_id == 3" class="float-right badge badge-danger">
							{{ this.catalogue.count_checks_authorize }}
						</span>
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'pays'">
				<router-link to="/pays" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-hand-holding-dollar"></i>
					<p>
						Pagos
						<span v-can="'add_pays_fast'" class="float-right badge badge-danger">
							{{ this.catalogue.count_paid_checks }}
						</span>
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'pays_paid'">
				<router-link to="/pays_paid" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-vault"></i>
					<p>
						Archivo de pagos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'payroll'">
				<router-link to="/payroll" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-file-invoice"></i>
					<p>
						Nominas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'income_paid'">
				<router-link to="/income_paid" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-sack-xmark"></i>
					<p>
						Archivo de Ingresos
					</p>
				</router-link>
			</li>
			<!-- <li class="nav-item" v-can="'incomes'">
				<router-link to="/incomes" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-money-bill-trend-up"></i>
					<p>
						Ingresos
					</p>
				</router-link>
			</li> -->
			<li class="nav-item">
				<router-link to="/profile" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-user-gear"></i>
					<p>
						Editar cuenta
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/report" active-class="active" class="nav-link">
					<i class="nav-icon  fa-solid fa-magnifying-glass-chart"></i>
					<p>
						Reportes
					</p>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
<style>
.indicator {
	background: red;
	color: #ffffff;
}
</style>